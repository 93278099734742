function Copyright () {
    return (
        <div class="copyright">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <p class="p-small">Copyright © 2020 <a href="https://micinvinacademy.com">Inovatik</a> - All rights reserved</p>
                </div>
            </div>
        </div>
    </div>
    );
}

export default Copyright;