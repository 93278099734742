function Instructor (){
    return (
        <div id="instructor" class="basic-1">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6 basic-6">
                        <img class="img-fluid" src="images/instructor.jpg" alt="alternative" />
                    </div>
                    <div class="col-lg-6 basic-6">
                        <div class="text-container">
                            <h2>I’m Timmy Your Trainer</h2>
                            <p>Hi everybody! I am Garry and I will be your main instructor durnig the SEO training course. I have more than 10 years experience in SEO and I am very passionate about this field. Register for the course and let's meet.</p>
                            <p>Teaching students all about the beste SEO techniques is something I love to do as a full-time job</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Instructor;