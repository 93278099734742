function Header() {
    return (
    <header id="header" class="header">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="text-container">
                        <div class="countdown">
                            <span id="clock"></span>
                        </div>
                        <h1>SEO Training Course</h1>
                        <p class="p-large">Do you feel like you're doing a lot of guess work when it comes to SEO for your website? Take the SEO training course to change that</p>
                        <a class="btn-solid-lg page-scroll" href="#register">REGISTER</a>
                        <a class="btn-outline-lg page-scroll" href="#instructor">DISCOVER</a>
                    </div>
                </div>
            </div>
        </div>

        <div class="outer-container">
            <div class="slider-container">
                <div class="swiper-container image-slider-1">
                    <div class="swiper-wrapper">
                        
                        <div class="swiper-slide" >
                            <img class="img-fluid" src="images/details-slide-1.jpg" alt="alternative" />
                        </div>

                        <div class="swiper-slide">
                            <img class="img-fluid" src="images/details-slide-2.jpg" alt="alternative" />
                        </div>

                        <div class="swiper-slide">
                            <img class="img-fluid" src="images/details-slide-3.jpg" alt="alternative" />
                        </div>

                    </div>
                    
                    <div class="swiper-button-next"></div>
                    <div class="swiper-button-prev"></div>
                    
                </div>
            </div>
        </div>

    </header>
    );
}

export default Header;