function Description () {
    return (
        <div id="description" class="basic-2">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <h2>What Will You Learn In Our SEO Focused Training Course</h2>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-6">
                        <ul class="list-unstyled li-space-lg first">
                            <li class="media">
                                <i class="bullet">1</i>
                                <div class="media-body">
                                    <h4>Optimizing your site for mobile devices</h4>
                                    <p>One of the keys of great SEO is having a mobile friendly website which works smoothly on all devices</p>
                                </div>
                            </li>
                            <li class="media">
                                <i class="bullet">2</i>
                                <div class="media-body">
                                    <h4>Understand how users search</h4>
                                    <p>It's not enough anymore to find relevant industry keywords and write huge amounts of content </p>
                                </div>
                            </li>
                            <li class="media">
                                <i class="bullet">3</i>
                                <div class="media-body">
                                    <h4>Write for humans optimize for engines</h4>
                                    <p>Write well structured and understandable articles not just a mix of paragraphs that contain keywords</p>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div class="col-lg-6">
                        <ul class="list-unstyled li-space-lg second">
                            <li class="media">
                                <i class="bullet">4</i>
                                <div class="media-body">
                                    <h4>Analyse your existing search traffic</h4>
                                    <p>A good action plan comes out of understanding where your current position is and the environment</p>
                                </div>
                            </li>
                            <li class="media">
                                <i class="bullet">5</i>
                                <div class="media-body">
                                    <h4>Keep updated with the latest changes</h4>
                                    <p>Google changes it's search indexing algorithm twice a year so it's important to stay updated with news</p>
                                </div>
                            </li>
                            <li class="media">
                                <i class="bullet">6</i>
                                <div class="media-body">
                                    <h4>Learn the most important ranking factors</h4>
                                    <p>Learn which are the most important search engine ranking factors and optimize your website accordnigly</p>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Description;