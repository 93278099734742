function Navigation() {
    return (
        <nav class="navbar navbar-expand-lg navbar-dark navbar-custom fixed-top">

            {/* <a class="navbar-brand logo-image" href="index.html"><img src="images/logo.svg" alt="alternative" /></a> */}
            
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarsExampleDefault" aria-controls="navbarsExampleDefault" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-awesome fas fa-bars"></span>
                <span class="navbar-toggler-awesome fas fa-times"></span>
            </button>

            <div class="collapse navbar-collapse" id="navbarsExampleDefault">
                <ul class="navbar-nav ml-auto">
                    <li class="nav-item">
                        <a class="nav-link page-scroll" href="#description">DETAILS</a>
                    </li>

                    <li class="nav-item">
                        <a class="nav-link page-scroll" href="#contact">CONTACT</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link page-scroll" href="https://lectures.micinvinacademy.com">LECTURES</a>
                    </li>
                </ul>
                <span class="nav-item social-icons">
                    <span class="fa-stack">
                    </span>
                    <span class="fa-stack">
                    </span>
                </span>
            </div>
        </nav>
    );
}

export default Navigation;