function Contact () {
    return (
        <div id="contact" class="form-3">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6">
                        <div class="text-container">
                            <h2>Contact Details</h2>
                            <p>For registration questions please get in touch using the contact details below. For any questions use the form.</p>
                        </div>
                    </div>      
                    <div class="col-lg-6">
                        <div class="text-container">        
                            <h3>Main Office Location</h3>
                            <ul class="list-unstyled li-space-lg">
                                <li class="media">
                                    <i class="fas fa-envelope"></i>
                                    <div class="media-body">
                                        <a class="light-gray" href="mailto:contact@micinvinacademy.com">contact@micinvinacademy.com</a>
                                    </div>
                                    <i class="fas fa-globe"></i>
                                    <div class="media-body">
                                        <a class="light-gray" href="www.micinvinacademy.com">www.micinvinacademy.com</a>
                                    </div>
                                </li>
                            </ul>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Contact;