import logo from './logo.svg';
import './App.css';
import './components/Preloader';
import Preloader from './components/Preloader';
import Navigation from './components/Navigation';
import Header from './components/Header';
import Instructor from './components/Instructor';
import Description from './components/Description';
import Contact from './components/Contact';
import Copyright from './components/Copyright';

function App() {
  return (
    <div className="App">
      <Preloader />
      <Navigation />
      <Header />
      <Instructor />
      <Description />
      <Contact />
      <Copyright />
    </div>
  );
}

export default App;
